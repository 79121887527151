import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dfbf6cda"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-overflow" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "mx-3 col" }
const _hoisted_5 = {
  key: 0,
  class: "text-success"
}
const _hoisted_6 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_7 = {
  key: 2,
  class: "text-gray"
}
const _hoisted_8 = { class: "row" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "mx-3 col" }
const _hoisted_11 = {
  key: 0,
  class: "text-success"
}
const _hoisted_12 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_13 = {
  key: 2,
  class: "text-gray"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_match_score_result_badge = _resolveComponent("match-score-result-badge")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_card, {
        bodyStyle: { padding: '1px' },
        style: { borderRadius: '20px' }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_table, {
            class: "knockout",
            columns: _ctx.columns[_ctx.store.state.currentLanguage],
            "data-source": _ctx.data.versusMatch,
            pagination: false,
            "row-key": (record) => record.versusMatchId
          }, {
            date: _withCtx(({ text: date }) => [
              _createElementVNode("p", null, _toDisplayString(date), 1)
            ]),
            teamOne: _withCtx(({ text: teamOne }) => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("img", {
                  src: teamOne.image ?? require('@/assets/logo-placeholder.svg'),
                  class: "col-3 knoctout-image"
                }, null, 8, _hoisted_3),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("p", null, _toDisplayString(teamOne.name[_ctx.store.state.currentLanguage]), 1),
                  (teamOne.result === 'w')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.t("competition.win")), 1))
                    : _createCommentVNode("", true),
                  (teamOne.result === 'l')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.t("competition.lose")), 1))
                    : (teamOne.result === 'a')
                      ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.t("competition.draw")), 1))
                      : _createCommentVNode("", true)
                ])
              ])
            ]),
            matchResult: _withCtx(({ text: matchResult }) => [
              _createVNode(_component_match_score_result_badge, {
                matchResult: matchResult,
                competitionId: _ctx.competitionId,
                subCompetitionId: _ctx.subCompetitionId,
                competitionSectionId: _ctx.competitionSectionId
              }, null, 8, ["matchResult", "competitionId", "subCompetitionId", "competitionSectionId"])
            ]),
            teamTwo: _withCtx(({ text: teamTwo }) => [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("img", {
                  class: "col-3 knoctout-image",
                  src: teamTwo.image ?? require('@/assets/logo-placeholder.svg')
                }, null, 8, _hoisted_9),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("p", null, _toDisplayString(teamTwo.name[_ctx.store.state.currentLanguage]), 1),
                  (teamTwo.result === 'w')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.t("competition.win")), 1))
                    : _createCommentVNode("", true),
                  (teamTwo.result === 'l')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.t("competition.lose")), 1))
                    : (teamTwo.result === 'a')
                      ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.t("competition.draw")), 1))
                      : _createCommentVNode("", true)
                ])
              ])
            ]),
            _: 1
          }, 8, ["columns", "data-source", "row-key"])
        ]),
        _: 1
      })
    ])
  ]))
}