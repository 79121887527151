
import useCompetitionRepositories from '@/_composables/useCompetitionRepositories'
import SatIcon from '@/components/SatIcon.vue'
import { FileImageOutlined, PlayCircleOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { defineComponent, toRefs, watch, ref, reactive } from 'vue'
import { format } from 'date-fns'
import { useI18n } from 'vue-i18n'
import { th } from 'date-fns/locale'
import { IImage, IVideo, QueryParams } from '@/_modules/types'
import VideoPlayer from '@/components/VideoPlayer.vue'
export default defineComponent({
  components: {
    SatIcon,
    FileImageOutlined,
    PlayCircleOutlined,
    LoadingOutlined,
    VideoPlayer
  },
  props: {
    competitionId: {
      type: Number,
      required: true
    },
    subCompetitionId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const currentPage = ref(1)
        const slick = ref();

    const limit = 3
    const totalPages = ref()
    const state = reactive({
      images: [] as IImage[],
      lastPage: 1,
      isFullVideo: false,
      videoPage: 1,
      videoLastPage:1,
      loadingMoreVideo: false,
      video: {
        // source: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        // poster: 'https://peach.blender.org/wp-content/uploads/title_anouncement.jpg?x11217'
      } as IVideo,
      videos: [] as IVideo[]
    })
    const { t } = useI18n({
      useScope: 'global'
    })
    const { competitionId, subCompetitionId } = toRefs(props)
    const now = format(new Date(), 'dd MMMM', { locale: th })
    const yearTh = new Date().getFullYear() + 543
    const updatedDate = ref(`${now} ${yearTh}`)
    const { getImages, fetchingImages, getVideos, fetchingVideos } = useCompetitionRepositories()
    const fetchImages = async(q: QueryParams) => {
      if (!competitionId.value || !subCompetitionId.value) {
        return
      }
      const result = await getImages(competitionId.value, subCompetitionId.value, q)
      if (result) {
        const { data, currentPage: page, lastPage, perPage,last_updated } = result.medias
        totalPages.value = lastPage
        if (data) {
          state.images = [...state.images, ...data]
        }
      }
    }
    const fetchVideos = async(q: QueryParams) => {
      if (!competitionId.value || !subCompetitionId.value) {
        return
      }
      const result = await getVideos(competitionId.value, subCompetitionId.value, q)
      if (result) {
        const { data, currentPage, lastPage, perPage,total } = result.medias
        if (data) {
                  state.videoLastPage = lastPage;

          state.videos = [...data]
          state.video = data[0]
          if (total === 3) {
            state.isFullVideo = true;
          }
        }
      }
    }
    const loadMoreImages = async () => {
      try {
        if (currentPage.value <= totalPages.value) {
          await fetchImages({ page: currentPage.value, size: limit })
        }
        currentPage.value++
      } catch (error) {
      }
    };
    const onClickVideoInPlaylist = (video: IImage) => {
      state.video.src = video.src
      // state.video.poster = `https://picsum.photos/id/20/200/100`
    }
    watch(() => [competitionId, subCompetitionId], () => {
      if (competitionId.value && subCompetitionId.value) {
        fetchImages({ page: currentPage.value, size: limit })
        fetchVideos({ page: currentPage.value, size: limit })
        currentPage.value++
      }
    }, { immediate: true })

const onNextVideo = async () => {
      if (state.lastPage === state.videoPage) {
        state.lastPage += 1;
        state.loadingMoreVideo = true;
        await fetchVideos({ size: 3, page: state.lastPage });

        setTimeout(() => {
          state.videoPage += 1;
          state.loadingMoreVideo = false;
          slick.value.next();
        }, 500);
      } else {
        slick.value.next();
        state.videoPage += 1;
      }
    };
      const onPreviousVideo = async() => {
      state.lastPage -= 1;
      await fetchVideos({ size: 3, page: state.lastPage });
      if (state.videoPage > 1) {
        state.videoPage -= 1;
        slick.value.prev();
      }
    };

    return {
      updatedDate,
      t,
      ...toRefs(state),
      onPreviousVideo,
      fetchingImages,
      onClickVideoInPlaylist,
      currentPage,
      totalPages,
      loadMoreImages,
      onNextVideo
    }
  }
})
