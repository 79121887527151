
import { defineComponent, reactive, toRefs, ref, watch, toRaw } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import { format } from "date-fns";
import { th } from "date-fns/locale";
import { useRequest } from "vue-request";
import SatIcon from "@/components/SatIcon.vue";
import SatCardCarousel from "@/components/carousel/SatCardCarousel.vue";
import {
  CalendarOutlined,
  FileProtectOutlined,
  ContactsOutlined,
  EnvironmentOutlined,
  CodeOutlined,
} from "@ant-design/icons-vue";
import SatBreadcrumb from "@/components/SatBreadcrumb.vue";
import { athleteService } from "@/_services";
import {
  Athlete,
  BaseResponse,
  BreadcrumbRoute,
  IAthlete,
  ICompetition,
  CompetitionInformation,
  SubCompetitionQueryParamsDto,
  ITeam,
  TeamInSubCompetitionQueryParamsDto,
} from "@/_modules/types";
import useCompetitionRepositories from "@/_composables/useCompetitionRepositories";
import CompetitionSection from "@/components/competitions/CompetitionSection.vue";
import CompetitionGallery from "@/components/competitions/CompetitionGallery.vue";
import { Helpers } from "@/_modules/helpers";
import CompetititonBanner from "@/views/competition/CompetitionBanner.vue";

export default defineComponent({
  name: "SubCompetition",
  components: {
    SatIcon,
    SatCardCarousel,
    SatBreadcrumb,
    CalendarOutlined,
    FileProtectOutlined,
    ContactsOutlined,
    EnvironmentOutlined,
    CodeOutlined,
    CompetitionSection,
    CompetitionGallery,
    CompetititonBanner,
  },
  setup() {
    const {
      getCompetition,
      getSubCompetition,
      fetchingSubCompetition,
      getTeams,
      fetchingTeamsInSubCompetition,
    } = useCompetitionRepositories();
    const { t } = useI18n({
      useScope: "global",
    });
    const store = useStore();
    const activeKey = ref(0);
    const route = useRoute();
    const state = reactive({
      loading: true,
      competition: {} as ICompetition,
      info: [
        {
          key: "year",
          title: { th: "ปีการแข่งขัน", en: "Year" },
          value: {
            en: "-",
            th: "-",
          },
          icon: "SatIcon",
          props: { name: "hearthCalendar", class: "me-2 fs-5" },
        },
        {
          key: "host",
          title: { th: "เจ้าภาพการแข่งขัน", en: "Host" },
          icon: "SatIcon",
          props: { name: "manCircle", cssClass: "me-2 fs-5" },
          value: {
            en: "-",
            th: "-",
          },
        },
        {
          key: "type",
          title: { th: "ประเภทการแข่งขัน", en: "Type" },
          icon: "SatIcon",
          props: { name: "reward", cssClass: "me-2 fs-5" },
          value: {
            en: "-",
            th: "-",
          },
        },
        {
          key: "startDate",
          title: { th: "วันที่เริ่มการแข่งขัน", en: "Start Date" },
          icon: "SatIcon",
          props: { name: "hearthCalendar", cssClass: "me-2 fs-5" },
          value: {
            en: "-",
            th: "-",
          },
        },
        {
          key: "endDate",
          title: { th: "วันที่จบการแข่งขัน", en: "End Date" },
          icon: "SatIcon",
          props: { name: "hearthCalendar", cssClass: "me-2 fs-5" },
          value: {
            en: "-",
            th: "-",
          },
        },
        {
          key: "winner",
          title: { th: "ทีมที่ชนะเลิศ", en: "Winner" },
          icon: "SatIcon",
          props: { name: "", cssClass: "me-2 fs-5" },
          value: {
            en: "-",
            th: "-",
          },
        },
      ],
      winnerLogo: "" as string,
      subCompetition: {} as ICompetition,
      tabs: [
        { type: "ประวัติ", title: "-" },
        { type: "รายชื่อ", title: "ทีมที่เข้าร่วม" },
        { type: "ตาราง", title: "การแข่งขัน" },
        { type: "คลัง", title: "ภาพและวิดีโอ" },
      ],
      tabsEn: [
        { type: "Record", title: "" },
        { type: "List", title: "Team Join" },
        { type: "Table", title: "Standings" },
        { type: "", title: "Images & Videos" },
      ],

      teams: [] as ITeam[],
    });
    const onChangeTab = (actived: number) => {
      switch (actived) {
        case 1:
          break;
      }
    };
    const routes = ref<BreadcrumbRoute[]>([
      {
        path: "competitions",
        breadcrumbName: t("label.overview.competitions"),
      },
      {
        path: "",
        breadcrumbName: "-",
      },
      {
        breadcrumbName: "-",
      },
    ]);

    const fetchAllTeam = async (
      id: number,
      subId: number,
      q: TeamInSubCompetitionQueryParamsDto
    ) => {
      const result = await getTeams(id, subId, q);
      if (!result) return;
      const { currentPage, lastPage, data, perPage } = result.sub_competition;
      state.teams = [...toRaw(state.teams), ...data];
      if (currentPage < lastPage) {
        await fetchAllTeam(id, subId, { page: currentPage + 1, size: perPage });
      }
    };

    watch(
      () => route.params,
      async (params) => {
        const { id, subId } = params;
        if (Number.isNaN(+id) || Number.isNaN(+subId)) return;
        await fetchAllTeam(+id, +subId, { page: 1, size: 15 });
        const competiton = await getCompetition(+id);
        routes.value[1].breadcrumbName =
          store.state.currentLanguage === "th"
            ? competiton?.name?.th ?? "-"
            : competiton?.name?.en ?? "-";
        routes.value[1].path = `${id}`;
        const subCompetition = await getSubCompetition(+id, +subId);
        routes.value[2].breadcrumbName =
          store.state.currentLanguage === "th"
            ? subCompetition?.name?.th ?? "-"
            : subCompetition?.name?.en ?? "-";

        state.subCompetition = subCompetition ?? ({} as ICompetition);
        state.tabs[0].title = subCompetition?.name?.th ?? "-";
        state.tabsEn[0].title = subCompetition?.name?.en ?? "-";

        state.winnerLogo = subCompetition?.winnerLogo ?? "";
        if (subCompetition) {
          state.info.forEach((ele) => {
            if (Object.keys(subCompetition).includes(ele.key)) {
              switch (ele.key) {
                case "year":
                  ele.value = {
                    th: "" + (+subCompetition.year + 543),
                    en: subCompetition.year,
                  };
                  break;
                case "host":
                  ele.value = {
                    th: subCompetition.host.th,
                    en: subCompetition.host.en,
                  };
                  break;
                case "type":
                  ele.value = {
                    th: subCompetition.type.th,
                    en: subCompetition.type.en,
                  };
                  break;
                case "startDate":
                  ele.value = {
                    th: subCompetition.startDate,
                    en: subCompetition.startDate,
                  };
                  break;
                case "endDate":
                  ele.value = {
                    th: subCompetition.endDate,
                    en: subCompetition.endDate,
                  };
                  break;
                case "winner":
                  ele.value = {
                    th: subCompetition.winner?.th,
                    en: subCompetition.winner?.en,
                  };
                  break;
              }
            }
          });
        }
      },
      { immediate: true }
    );
    return {
      t,
      store,
      ...toRefs(state),
      routes,
      activeKey,
      onChangeTab,
    };
  },
});
