
import { defineComponent, reactive, toRefs, ref, watch, onMounted } from "vue";
import SatBreadcrumb from "@/components/SatBreadcrumb.vue";
import VersusBanner from "@/components/competitions/VersusBanner.vue";
import { BreadcrumbRoute } from "@/_modules/types";
import { useRoute } from "vue-router";
import useCompetitionRepositories from "@/_composables/useCompetitionRepositories";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
export default defineComponent({
  components: {
    VersusBanner,
    SatBreadcrumb,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n({
      useScope: "global",
    });
    const routes = ref<BreadcrumbRoute[]>([
      {
        path: "competitions",
        breadcrumbName: "ภาพรวมรายการแข่งขันทั้งหมด",
      },
      {
        path: "",
        breadcrumbName: "-",
      },
      {
        path: "",
        breadcrumbName: "-",
      },
      {
        breadcrumbName: "รายละเอียดการแข่งขัน",
      },
    ]);
    const route = useRoute();
    const { getVersusMatch, getCompetition, getSubCompetitions } =
      useCompetitionRepositories();
    onMounted(async () => {
      try {
        await onFetchVersusMatch();
      } catch (error) {}
    });

    const state = reactive({
      info: [
        {
          key: "round",
          title: {
            th: "รอบการแข่งขัน",
            en: "Round",
          },
          value: {
            en: "-",
            th: "-",
          },
          icon: "CodeOutlined",
          props: { class: "me-2 fs-5" },
        },
        {
          key: "host",
          title: {
            th: "เจ้าบ้าน",
            en: "Home Team",
          },
          value: {
            en: "-",
            th: "-",
          },
          icon: "CodeOutlined",
          props: { class: "me-2 fs-5" },
        },
        {
          key: "people",
          title: {
            th: "จำนวนผู้เข้าชม",
            en: "Visitors",
          },
          value: {
            en: "-",
            th: "-",
          },
          icon: "CodeOutlined",
          props: { class: "me-2 fs-5" },
        },
      ],
      banner: {},
      information: {},
    });

    const onFetchVersusMatch = async () => {
      const data = await getVersusMatch(
        +route.params.id,
        +route.params.subId,
        +route.params.competitionId,
        +route.params.versusId
      );
      state.banner = data.banner;
      state.information = data.information;
      state.info.map((ele) => {
        const { key } = ele;
        switch (key) {
          case "round":
            ele.value = data.information.round;
            break;
          case "host":
            ele.value = data.information.holder;
            break;
          case "people":
            ele.value = data.information.visitor;
            break;
        }
      });
    };

    watch(
      () => route.params.id,
      async (newId) => {
        if (Number.isNaN(+newId)) return;
        const result = await getCompetition(+route.params.id);
        const resultSub = await getSubCompetitions(+route.params.id, {});
        if (result && resultSub) {
          routes.value[1].breadcrumbName = result.name?.th;
          routes.value[1].path = `/${+route.params.id}`;
          const routeSub = resultSub?.sub_competition?.data?.find(
            (p) => +p.id === +route.params.subId
          );
          routes.value[2].breadcrumbName = routeSub?.name?.th ?? "";
          routes.value[2].path = `/${+route.params.subId}`;
        }
      },
      { immediate: true }
    );

    return {
      t,
      store,
      ...toRefs(state),
      routes,
    };
  },
});
