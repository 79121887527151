
import { defineComponent, PropType, toRefs } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
export default defineComponent({
  props: {
    matchResult: Object as PropType<any>,
    competitionId: {
      type: Number,
      required: true,
    },
    subCompetitionId: {
      type: Number,
      required: true,
    },
    competitionSectionId: {
      type: Number,
      required: false,
    },
  },
  setup(props) {
    const { t } = useI18n({
      useScope: "global",
    });
        const store = useStore();

    return {
            t,
            store

    }
  },
});
