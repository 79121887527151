
import { defineComponent, reactive, toRefs, ref, watch, toRaw } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { Helpers } from "@/_modules/helpers";

export default defineComponent({
  props: {
    banner: Object,
  },
  setup() {
    const store = useStore();
    const helpers = Helpers;

    const { t } = useI18n({
      useScope: "global",
    });
    return {
      t,
      helpers,
      store,
    };
  },
});
